<template>
  <b-card class="padding-card-container">
    <div class="mb-3">
        <span>Administra los slots de la tienda y su capacidad para una gestión más eficiente.</span>
    </div>
    <b-tabs>
      <b-tab title="Lunes a Viernes" active>
        <detail-store :daysInfo.sync="mondayToFriday" :days="weekdays.business_days" @getStoreManagement="getStoreManagement"></detail-store>        
      </b-tab>
      <b-tab title="Sábados">
        <detail-store :daysInfo.sync="saturday" :days="weekdays.saturday" @getStoreManagement="getStoreManagement"></detail-store>
      </b-tab>
      <b-tab title="Domingos">
        <detail-store :daysInfo.sync="sunday" :days="weekdays.sunday" @getStoreManagement="getStoreManagement"></detail-store>
      </b-tab>
    </b-tabs>
  </b-card>
</template>

<script>
import { mapGetters } from 'vuex'
import DetailStore from './components/DetailStore.vue'
export default {
  name: 'store-management',
  components: {DetailStore},
  data () {
    return {     
      mondayToFriday: null,
      saturday: null,
      sunday: null,
      weekdays: {
        business_days: ['lunes', 'martes', 'miércoles', 'jueves', 'viernes'],
        saturday: ['sábados'],
        sunday: ['domingos']
      } 
    }
  },
  watch: {
    mySession () {
      if (this.mySession.id) this.setInitialData()
    }
  },
  computed: {
    ...mapGetters({
      mySession: 'getSession',
      getStore: 'getStore'
    })
  },
  mounted () {
    this.getAllInitialData()
  },
  methods: {
    getAllInitialData () {
      if (this.mySession.id) this.setInitialData()
    }, 
    setInitialData () {
      this.getStoreManagement()
    },
    getStoreManagement () {
      const params = {
        id: this.$route.query?.id || this.$route.params?.id
      }
      this.$store.dispatch('fetchService', { name: 'getStore', params, onSuccess: () => this.onSuccessGetStore()})
    },
    onSuccessGetStore () {
      this.mondayToFriday = this.filterInformationByDay('lunes')[0] || {}
      this.saturday = this.filterInformationByDay('sabado')[0] || {}
      this.sunday = this.filterInformationByDay('domingo')[0] || {} 
    }, 
    filterInformationByDay (day) {
      return this.getStore.filter(item => {
        const formatterDay = item.weekdays.map(day => this.removeDiacriticsAndConvertToLowercase(day))
        return formatterDay.includes(this.removeDiacriticsAndConvertToLowercase(day))
      })
    },
    removeDiacriticsAndConvertToLowercase(text) {
      return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase()
    }
  }
}
</script>

<style>
.padding-card-container {
    padding: 0.5rem;
}
</style>