<template>
    <div>
        <div v-if="!!showInfo">
            <h4 class="title-personalize">Tienda</h4>
            <div class="d-flex container-hours justify-content-between mb-3">
                <div class="d-flex flex-column">
                    <span>Apertura</span>
                    <span class="d-flex align-items-center ml-1 mt-1">
                        <feather-icon
                            size="18"
                            icon="ClockIcon"
                            class="mr-05"
                        />
                        {{ info.opening_time }}
                    </span>    
                </div>
                <div class="d-flex flex-column">
                    <span>Cierre</span>
                    <span class="d-flex align-items-center ml-1 mt-1">
                        <feather-icon
                            size="18"
                            icon="ClockIcon"
                            class="mr-05"
                        />
                        {{ info.closing_time }}
                    </span>
                </div> 
            </div>
            <h4 class="title-personalize">Slots</h4>
            <div class="card-personalize-slots" v-for="(slot, index) in info.slot_configs" :key="index">
                <b-card>
                    <div class="d-flex justify-content-between">
                        <div class="d-flex align-items-center">
                            <span class="d-flex align-items-center container-number">{{ index + 1 }}</span>
                            <div class="d-flex flex-column ml-3 range">
                                <span>Rango</span>
                                <div class="ml-05 mt-05">
                                    <feather-icon
                                        size="18"
                                        icon="ClockIcon"
                                        class="mr-05"
                                    />
                                    <span class="color-grey-transparent">{{slot.initial_time}} - {{slot.end_time}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex align-items-center">
                            <b-form-group
                                label-for="slot-capacity"
                                label="Capacidad"
                            >
                                <b-form-input
                                type="number"
                                id="slot-capacity"
                                @update="changeValue('capacity')"
                                v-model="slot.capacity"
                                />
                            </b-form-group>
                            <b-form-checkbox
                            switch
                            class="ml-2 checkbox-switch checkbox-green-bg"
                            v-model="slot.enabled"
                            @change="changeValue('enabled')" />
                        </div>
                    </div>
                </b-card>
            </div>
            <div class="card-personalize-slots d-flex justify-content-end card-total-capacity">
                <b-card> 
                    <span class="margin-capacity-text"><strong>Capacidad Total del Día</strong></span>
                    <span class="margin-capacity-number"><strong>{{ totalCapacity }}</strong></span>
                </b-card>
            </div>
            <div class="width-button d-flex align-items-center justify-content-end">
                <b-button class="padding-button" :disabled="!!loading.btn" variant="warning" @click="confirmChanges">
                    {{$t('Guardar')}}
                </b-button>
            </div> 
        </div>
        <div v-if="!showInfo & !loading.first" class="d-flex flex-column align-items-center">
            <img src="@/assets/images/logo/icon_close.svg" alt="close" class="img-close"/>
            <h1 class="text-close">La tienda permanece cerrada los {{ days.toString() }}.</h1>
        </div>
        <div v-if="!showInfo && !!loading.first">
            <div class="center-spinner">
                <b-spinner />
            </div>
        </div>
    </div>
    
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'detail-store',
  props: ['daysInfo', 'days'],
  data() {
    return { 
      info: {},
      loading: {
        first: true,
        btn: true
      },
      showInfo: false,
      totalCapacity: 0
    }
  },
  watch: {
    daysInfo () {
      this.setInitialData()
    }
  },
  computed: {
    ...mapGetters({
      store: 'putStore'
    })
  },
  mounted () {
    this.setInitialData()
  },
  methods: {
    setInitialData() {
      if (!!this.daysInfo) {
        this.info = {
          ...this.daysInfo
        }
        this.loading.first = false   
      }
      if (!!this.info && !!this.info.enabled && this.info.slot_configs?.length > 0) this.showInfo = !!Object.entries(this.info).length > 0 
      this.sumOfCapacities(this.info)
    },
    confirmCurrentDay () {
      const tex_days = ['domingos', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábados']
      const number_day = new Date().getDay()
      return tex_days[number_day]
    },
    confirmChanges () { 
      const current_day = this.confirmCurrentDay()
      if (this.days.includes(current_day)) {
        this.$confirm(this.$t('msg-confirm-change-saved'), this.savedChange, 'Advertencia')
      } else {
        this.savedChange()
      }
    },
    savedChange () {
      this.loading.first = true
      this.loading.btn = true
      this.showInfo = false
      const filterInfoSlots = this.info.slot_configs.map(item => {
        return {
          capacity: Number(item.capacity),
          enabled: item.enabled,
          id: item.id
        }
      })
      const params = {
        id: this.$route.query?.id || this.$route.params?.id
      }
      const queryParams = {
        slot_configs: filterInfoSlots,
        weekdays: this.info.weekdays
      }
      this.$store.dispatch('fetchService', { name: 'putStore', params, queryParams, onSuccess: (resp) => this.onSuccessPutStore(resp), onError: (err) => this.onErrorPutStore(err)})
    },
    onSuccessPutStore (data) {
      this.loading.btn = true
      this.$success(this.$t('msg-exito-actualizar'))
      this.$emit('getStoreManagement')
    },
    onErrorPutStore (err) {
      this.loading.btn = true
    },
    changeValue (type) {
      if (type === 'capacity') this.sumOfCapacities(this.info)
      this.loading.btn = false
    },
    sumOfCapacities (info) {
      if (!!info.slot_configs) {
        const capacities = info.slot_configs.map(item => Number(item.capacity))
        this.totalCapacity = capacities.reduce((a, b) => a + b, 0) 
      }
    }
  }

}
</script>

<style>

.container-hours{    
    width: 40%;
}

.mr-05 {
    margin-right: 0.5rem !important
}
.ml-05 {
    margin-left: 0.5rem !important
}
.mt-05 {
    margin-top: 0.5rem !important
}
.title-personalize {
    color: #044389;
    margin: 2rem 0rem 1rem 0rem;
}
.card-personalize-slots, .width-button {
    width: 80%;
}
.card-personalize-slots .card {
    border: 1px solid #E2E8F0;
    border-radius: 8px;
    box-sizing: border-box;
    filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.1));
    margin-bottom: 1rem !important;
}
.card-total-capacity .card-body {
    padding: 1rem;
}
.container-number{
    border: 1px solid #1B4284;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    color: #044389;
    font-size: 17px;
    font-weight: 500;
}
.color-grey-transparent {
    color: #AEAEAE;
}
.margin-capacity-text {
    margin: 0rem 3rem 0rem 1rem;
}
.margin-capacity-number {
    margin: 0 3rem;
}
.checkbox-green-bg > .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #0FA828;
    background-color: #0FA828;
 }
 .text-close {
    font-weight: 400;
    font-size: 25px;
    line-height: 30px;
    text-align: center;
    color: #5C5C5C;
    margin-bottom: 5rem;
 }
 .img-close {
    margin: 4rem 0;
 }
 .center-spinner {
    display: flex;
    justify-content: center;
}
@media (max-width: 767px) {
      .card-personalize-slots .d-flex {
        flex-direction: column;
        justify-content: center;
      }
       
      .range, .checkbox-switch {
        margin: 1rem 0 !important;
      }
      .card-total-capacity .card-body {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
      .card-total-capacity .card-body span {
        margin: 0 !important;
        text-align: center;
      }
      .card-personalize-slots, .width-button {
        width: 100%;
      }
}
</style>